import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllInternalResearch = () => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.getAllInternalResearch();
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.GET_ALL_INTERNAL_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const insertInternalResearch = (payload) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.insertInternalResearch();
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm đề tài thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.INSERT_INTERNAL_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: `Thêm đề tài thất bại! ${error.response}`
			})
		);
		return false;
	}
};

export const updateInternalResearchStatus = (id, status) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.updateInternalResearchStatus(id, status);
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_SUCCESS,
			id,
			status,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_STATUS_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

export const updateInternalResearch = (payload) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.updateInternalResearch(payload);
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_SUCCESS,
			payload,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(notification({ type: 'success', message: 'Update thành công' }));
		return;
	}
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.UPDATE_INTERNAL_RESEARCH_FAILURE,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
	});
	dispatch(notification({ type: 'error', message: 'Update thất bại!' }));
};

export const deleteInternalResearch = (id) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.deleteInternalResearch(id);
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_SUCCESS,
			id,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.DELETE_INTERNAL_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const filterInternalResearch = (payload) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.filterInternalResearch(payload);
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const filterInternalResearchV2 = (payload) => async (dispatch) => {
	const api = API_URLS.INTERNAL_RESEARCH.filterInternalResearchV2(payload);
	dispatch({
		type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.INTERNAL_RESEARCH.FILTER_INTERNAL_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.INTERNAL_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};
