import React, { useState } from 'react';

import MonographTable from '../../containers/Monograph/MonographTable';
import MonographButton from '../../containers/Monograph/MonographButton';
import { valueFormat } from '../../utils/valueFormat';

function Monograph(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [date, setDate] = useState(null);
	const list = window.location.href.split('/');
	const lastValueList = list[list.length - 1].split('=');
	const selectedProfileId =
		lastValueList.length > 1 ? lastValueList[lastValueList.length - 1] : null;

	return (
		<div style={{ padding: 8, minHeight: 'calc(100vh - 178px)', position: 'relative' }}>
			<MonographButton
				type={props.type}
				department={props.department}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
				setAnchorEl={setAnchorEl}
			/>
			<MonographTable
				adminTab={props.adminTab}
				type={props.type}
				department={props.department}
				selectedProfileId={selectedProfileId}
				handleReload={props.handleReload}
				reportAnchorEl={anchorEl}
				setReportAnchorEl={setAnchorEl}
				dateString={date}
				setDateString={setDate}
				date={{
					startAt: valueFormat.stringToDate(date?.startAt),
					finishAt: valueFormat.stringToDate(date?.finishAt)
				}}
			/>
		</div>
	);
}

export default Monograph;
