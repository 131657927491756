import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import {
	Grid,
	Button,
	Select,
	Divider,
	TextField,
	InputLabel,
	Typography,
	FormControl,
	FormHelperText,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Chip,
	MenuItem,
	Checkbox,
	FormControlLabel,
	Tooltip
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox, Visibility, CheckBoxOutlineBlank } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import FileUploadModal from '../../File/FileUploadModal';
import Other from '../ScientificResearchInsertForm/Other';
import FilePreviewModal from '../../File/FilePreviewModal';
import Chapter from '../ScientificResearchInsertForm/Chapter';
import Conference from '../ScientificResearchInsertForm/Conference';
import WorkingPaper from '../ScientificResearchInsertForm/WorkingPaper';
import JournalDomestic from '../ScientificResearchInsertForm/JournalDomestic';
import ConferenceDomestic from '../ScientificResearchInsertForm/ConferenceDomestic';
import JournalInternational from '../ScientificResearchInsertForm/JournalInternational';
import EmploymentContractTable from '../../../components/EmployContractModal/EmploymentContractTable';
import EmploymentContractDetailTable from '../../../components/EmployContractModal/EmploymentContractDetailTable';

import { uploadFile } from '../../../reducer/file/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';
import { updateScientificResearch } from '../../../reducer/scientificResearch/action';
import { updateScientificResearchOther } from '../../../reducer/scientificResearchOther/action';
import {
	getEmploymentContractById,
	getAllEmploymentContractByProfileId
} from '../../../reducer/employmentContract/action';
import ROUTER from '../../../constants/router';
import ScientificResearchTimeTable from '../ScientificResearchTimeTable/ScientificResearchTimeTable';
import { notification } from '../../../reducer/home/action';

function ScientificResearchUpdateModal(props) {
	const { employmentContractDetail, updating, setUpdating } = props;
	const [disabled, setDisabled] = useState(true);

	const [focusProfile, setFocusProfile] = useState(null);

	const [detail, setDetail] = useState(null);
	const [values, setValues] = useState(null);
	const [divideKPI, setDivideKPI] = useState(false);
	const [KPIList, setKPIList] = useState([]);
	const [profileList, setProfileList] = useState([]);
	const [helperText, setHelperText] = useState(false);
	const [timeResearchYear, setTimeResearchYear] = useState(2023);
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const [isDisableBtn, setIsDisableBtn] = useState(false);
	const dispatch = useDispatch();

	const isCheckedForKPI = (profile) => {
		if (divideKPI) return KPIList.find((e) => e?.profileId === profile.profileId);

		return (
			typeof values?.profileIdForKpi === 'number' && values?.profileIdForKpi === profile.profileId
		);
	};

	const dateFormat = (value) => (value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ') : null);

	useEffect(() => {
		if (updating !== null) resetValues();
		if (props.listJournalDomestic?.length === 0) props.getAllJournalDomestic();
		if (props.listConference?.length === 0) props.getAllConference();
	}, [updating]);

	useEffect(() => {
		if (values?.profileIdForKpi) props.getAllEmploymentContractByProfileId(values?.profileIdForKpi);
	}, [values?.profileIdForKpi]);

	useEffect(() => {
		if (employmentContractDetail)
			setValues({
				...values,
				profileIdForKpi: employmentContractDetail?.profile?.id
			});
	}, [employmentContractDetail]);

	useEffect(() => {
		const contract = props?.employmentContractListById?.find(
			(e) => e?.contract?.id === updating?.contractIdForKpi
		)?.contract;
		if (contract?.duration !== 1000) {
			if (contract?.kpi?.id === updating?.kpiIdForKpi) setDetail(contract?.kpi);
		} else {
			setDetail(contract?.listKpi?.find((e) => e.id === updating?.kpiIdForKpi));
		}
	}, [props?.employmentContractListById, updating?.contractIdForKpi]);

	useEffect(() => {
		setDetail(null);
		setValues(null);
		setProfileList([]);
	}, [props.filter]);

	useEffect(() => {
		if (values?.timeResearchDetailCode) {
			const selectedYear = props.timeResearchInformationList?.find(
				(info) =>
					!!info.timeResearchDetail.find((detail) => detail.code === values?.timeResearchDetailCode)
			)?.year;

			setTimeResearchYear(selectedYear);
		}
	}, [values?.timeResearchDetailCode]);

	useEffect(() => {
		if (values && props.isUploading === false && props.fileUrl !== '') {
			const listKpiIdForKpi = JSON.stringify(
				KPIList.map((e) => ({
					...e,
					coef: parseFloat(e?.coef)
				}))
			);

			const tempListAuthor = values?.listAuthor?.split(',').map((author) => author.trimStart());
			setValues({
				...values,
				fileUrl: props.fileUrl
			});
			props.updateScientificResearch(values.id, {
				...values,
				listAuthor: tempListAuthor,
				researchProjectProfile: profileList,
				journalId: values?.journalId,
				journalDomesticId: values?.journalDomesticId,
				conferenceId:
					props.filter === 'conference'
						? values.conferenceId
						: props.filter === 'conferenceDomestic'
							? values.conferenceDomesticId
							: null,
				conferenceDomesticId: null,
				profileId: props.profile?.id,
				scientificResearchProfile: profileList,
				type: props.type,
				fileUrl: props.fileUrl,
				listKpiIdForKpi: divideKPI ? listKpiIdForKpi : null,
				acceptedDay: dateFormat(values?.acceptedDay),
				submissionDay: dateFormat(values?.submissionDay),
				dashboardYear: new Date(dateFormat(values?.acceptedDay)).getFullYear()
			});
		}
	}, [props.isUploading]);
	const getCode = (profileId) => {
		return props.profileList.find((e) => e.id === profileId)?.code ?? null;
	};
	const getName = (profileId) => {
		return props.profileList.find((e) => e.id === profileId)?.name ?? null;
	};
	const resetValues = () => {
		let tempListAuthor = '';
		updating.listAuthor?.forEach((author) => {
			if (tempListAuthor === '') tempListAuthor += author;
			else tempListAuthor += `, ${author}`;
		});

		if (updating?.listKpiIdForKpi) {
			const listKpiIdForKpi = JSON.parse(updating?.listKpiIdForKpi);
			setKPIList(listKpiIdForKpi);
			if (listKpiIdForKpi) setDivideKPI(true);
		}

		setValues({
			...updating,
			listAuthor: tempListAuthor,
			journalId: updating?.journalId,
			journalDomesticId: updating?.journalDomesticId,
			conferenceId:
				props.filter === 'conference'
					? updating.conferenceId
					: props.filter === 'conferenceDomestic'
						? updating.conferenceDomesticId
						: null,
			conferenceDomesticId: null,
			profileId: props.profile?.id,
			scientificResearchProfile: profileList,
			type: props.type,
			acceptedDay: new Date(updating.acceptedDay),
			submissionDay: new Date(updating.submissionDay),
			dashboardYear: new Date(dateFormat(new Date(updating.acceptedDay))).getFullYear()
		});
		setProfileList(
			([...updating.scientificResearchProfile] || []).map((item) => ({
				...item,
				name: item.isExternalTeacher ? item.name : getName(item.profileId),
				code: item.isExternalTeacher ? '' : getCode(item.profileId)
			}))
		);

		props.getEmploymentContractById(updating.contractIdForKpi);

		const contract = props.employmentContractListById?.find(
			(e) => e.contract.id === updating.contractIdForKpi
		)?.contract;
		setDetail(contract?.listKpi?.find((e) => e.id === updating.kpiIdForKpi));

		const timeResearchYearId = parseInt(updating.timeResearchDetailCode?.split('-')[0]);
		const year = props.timeResearchInformationList?.find(
			(e) => e.year === timeResearchYearId
		)?.year;
		setTimeResearchYear(year);
	};

	const handleChangeForm = (name, value) => {
		if (name === 'timeResearchDetailCode') {
			setValues((current) => ({
				...current,
				[name]: value === 'null' ? null : value
			}));
		} else if (name === 'purposeId') {
			setValues((current) => ({
				...current,
				[name]: value,
				profileIdForKpi: null,
				kpiIdForKpi: null,
				contractIdForKpi: null,
				profileIdForReward: null
			}));
		} else {
			setValues((current) => ({ ...current, [name]: value }));
		}
	};

	const handleChangeProfileList = (index, name, value) => {
		setProfileList((temp) => {
			const tempElm = temp[index];
			const updatedElm = { ...tempElm, [name]: value };
			const updatedTemp = [...temp];
			updatedTemp.splice(index, 1, updatedElm);
			return updatedTemp;
		});
	};

	const handleInsert = () => {
		const temp = profileList;
		temp.push({
			profileId: null,
			roleInPaper: 'main'
		});
		setProfileList([...temp]);
	};

	const handleRemove = (index) => {
		if (profileList[index].profileId === values?.profileIdForKpi)
			handleChangeForm('profileIdForKpi', null);

		const temp = profileList.filter((e, id) => id !== index);
		setProfileList([...temp]);
	};

	const handleSendFeedback = () => {
		props.history.push({
			pathname: ROUTER.NOTIFICATION,
			state: {
				sendTo: 'personal',
				title: 'Thông báo về sai sót thông tin trong CTKH',
				chosenProfiles: profileList.reduce((accumulator, currentValue) => {
					accumulator.push(currentValue.profileId);
					return accumulator;
				}, []),
				data: updating,
				dataType: 'scientificResearch'
			}
		});
	};

	const handleSubmit = (e) => {
		const listKpiIdForKpi = JSON.stringify(
			KPIList.map((e) => ({
				...e,
				coef: parseFloat(e?.coef)
			}))
		);

		if (disabled) {
			setDisabled(false);
		} else {
			if (values.purposeId === 1) {
				if (!values?.profileIdForKpi) {
					if (!detail) {
						dispatch(
							notification({
								type: 'warning',
								message: 'Phải chọn 1 người tính KPI cho bài báo!'
							})
						);
						return;
					}
				}

				if (!detail) {
					dispatch(
						notification({
							type: 'warning',
							message: 'Phải chọn năm tính KPI!'
						})
					);
					return;
				}
			}

			if (profileList.findIndex((e) => e.profileId === null) < 0) {
				const tempListAuthor = values?.listAuthor?.split(',').map((author) => author.trimStart());
				props
					.updateScientificResearch(values.id, {
						...values,
						listAuthor: tempListAuthor,
						researchProjectProfile: profileList,
						journalId: values?.journalId,
						journalDomesticId: values?.journalDomesticId,
						conferenceId:
							props.filter === 'conference'
								? values.conferenceId
								: props.filter === 'conferenceDomestic'
									? values.conferenceDomesticId
									: null,
						conferenceDomesticId: null,
						profileId: props.profile?.id,
						scientificResearchProfile: profileList,
						type: props.type,
						listKpiIdForKpi: divideKPI ? listKpiIdForKpi : null,
						acceptedDay: dateFormat(values?.acceptedDay),
						submissionDay: dateFormat(values?.submissionDay),
						dashboardYear: new Date(dateFormat(values?.acceptedDay)).getFullYear()
					})
					.then((success) => {
						if (success) {
							props.statisticUnaccepted({
								departmentId: 0,
								profileId: props.profile?.id
							});
							if (props.role === 'sudo-admin')
								props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
						}
					});

				setValues(null);
				setDisabled(true);
				setProfileList([]);
				setUpdating(null);
				setHelperText(false);
			} else {
				setHelperText(true);
			}
		}
	};

	const handleClose = () => {
		if (!disabled) {
			resetValues();
			setDisabled(true);
		} else {
			setUpdating(null);
			setDisabled(true);
			setDivideKPI(false);
			setKPIList([]);
		}
	};

	const handleOpenUploadModal = () => setUploadModalOpen(true);
	const handleCloseUploadModal = () => setUploadModalOpen(false);
	const handleOpenPreviewModal = () => setPreviewModalOpen(true);
	const handleClosePreviewModal = () => setPreviewModalOpen(false);

	const handleUpload = (files) => {
		if (props.filter !== 'other') {
			props
				.uploadFile(values.id, 'scientificResearch', files)
				.then((success) => {
					if (success) setUploadModalOpen(false);
				})
				.catch();
		} else {
			props
				.uploadFile(values.id, 'scientificResearchOther', files)
				.then((success) => {
					if (success) setUploadModalOpen(false);
				})
				.catch();
		}
	};

	const contractList = [];
	props?.employmentContractListById.forEach((e) => contractList.push(e.contract));

	const profileListFromProps = [{ id: -1, name: '' }];
	props.profileList.forEach((e) => profileListFromProps.push(e));

	var timeResearchDetailList =
		props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
			?.timeResearchDetail || [];
	timeResearchDetailList = timeResearchDetailList.filter(
		(e) => !e.allowedUpdateNum && e.type === 'scientificResearch'
	);

	const yearList = [];
	props.timeResearchInformationList
		.sort((a, b) => b.year - a.year)
		.forEach((e) => {
			yearList.push(e.year);
		});

	const propsValues = {
		values: values,
		updating: updating,
		disabled: disabled,
		profileList: profileList,
		setValues: setValues,
		handleInsert: handleInsert,
		handleRemove: handleRemove,
		handleChangeForm: handleChangeForm,
		handleChangeProfileList: handleChangeProfileList
	};

	const renderLockButton = () => {
		if (!values?.locked) return null;
		return (
			<Chip
				variant="outlined"
				label={`Đã khóa tính ${
					(values?.locked === 'kpi' && 'KPI') ||
					(values?.locked === 'reward' && 'thưởng') ||
					(values?.locked === 'timeResearch' && 'giờ nghiên cứu')
				}`}
				color="primary"
				style={{ fontSize: 15, float: 'right', marginRight: 16 }}
			/>
		);
	};

	const getTypeAsTitle = (type) => {
		switch (type) {
			case 'journalInternational':
				return 'trên tạp chí quốc tế';
			case 'journalDomestic':
				return 'trên tạp chí trong nước';
			case 'conference':
				return 'trên hội thảo quốc tế';
			case 'conferenceDomestic':
				return 'trên hội thảo trong nước';
			case 'chapter':
				return 'là chương sách';
			case 'workingPaper':
				return 'đang thực hiện';
			case 'other':
				return 'trên tạp chí khác';
			default:
				return '';
		}
	};

	const needCalKpi = useMemo(() => {
		if (values?.purposeId !== 1) {
			return false;
		}
		return values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0;
	}, [values]);

	const needCalHour = useMemo(() => {
		return values?.purposeId === 5;
	}, [values]);

	const isFullSize = useMemo(() => {
		return needCalKpi || needCalHour;
	}, [needCalKpi, needCalHour]);

	const selectedTimeResearchDetail = useMemo(
		() => timeResearchDetailList?.find((e) => e.code === values?.timeResearchDetailCode),
		[timeResearchDetailList, values]
	);
	const mainProfileIds = useMemo(() => {
		return profileList.filter((e) => e.roleInPaper === 'main').map((e) => e.profileId);
	}, [profileList]);

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth={isFullSize ? 'xl' : 'md'}
			open={updating !== null}
			onClose={() => {
				setUpdating(null);
				setDisabled(true);
			}}
		>
			<DialogTitle>
				{disabled
					? `Chi tiết bài báo ${getTypeAsTitle(props.filter)}`
					: `Cập nhật bài báo ${getTypeAsTitle(props.filter)}`}
				{renderLockButton()}
			</DialogTitle>
			<DialogContent style={{ display: 'flex', overflowX: 'hidden' }}>
				<div
					style={{
						display: 'flex',
						paddingRight: 16,
						minWidth: isFullSize ? '50%' : '100%',
						maxWidth: isFullSize ? 960 : '100%'
					}}
				>
					<Grid container spacing={2}>
						{props.filter === 'journalInternational' && <JournalInternational {...propsValues} />}
						{props.filter === 'journalDomestic' && <JournalDomestic {...propsValues} />}
						{props.filter === 'conference' && <Conference {...propsValues} />}
						{props.filter === 'conferenceDomestic' && <ConferenceDomestic {...propsValues} />}
						{props.filter === 'chapter' && <Chapter {...propsValues} />}
						{props.filter === 'workingPaper' && <WorkingPaper {...propsValues} />}
						{props.filter === 'other' && <Other {...propsValues} />}

						{props.filter !== 'workingPaper' && values?.purposeId === 5 && (
							<>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										size="small"
										value={timeResearchYear}
										disabled={disabled}
										SelectProps={{ native: true }}
										label="Năm học tính giờ nghiên cứu"
										variant="outlined"
										onChange={(event) => setTimeResearchYear(parseInt(event.target.value))}
									>
										{yearList.map((e) => (
											<option key={`${e} - ${e + 1}`} value={parseInt(e)}>
												{e} - {e + 1}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item xs={9}>
									<TextField
										select
										fullWidth
										size="small"
										disabled={disabled}
										label="Nhiệm vụ tính giờ nghiên cứu"
										variant="outlined"
										value={values?.timeResearchDetailCode || null}
										onChange={(event) =>
											handleChangeForm('timeResearchDetailCode', event.target.value)
										}
										InputLabelProps={{
											shrink: !(
												values?.timeResearchDetailCode === undefined ||
												values?.timeResearchDetailCode === null
											)
										}}
										error={!values?.timeResearchDetailCode}
									>
										{timeResearchDetailList.length > 0 ? (
											timeResearchDetailList?.map((e) => (
												<MenuItem
													key={e.code}
													value={e.code}
												>{`${e.description} - ${e.value}(h)`}</MenuItem>
											))
										) : (
											<MenuItem value={null} disabled>
												Chưa có dữ liệu
											</MenuItem>
										)}
									</TextField>
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<TextField
								fullWidth
								size="small"
								label="Ghi chú"
								variant="outlined"
								value={values?.note || ''}
								disabled={disabled || values?.locked === 'kpi' || values?.locked === 'reward'}
								multiline
								rows={3}
								onChange={(event) => handleChangeForm('note', event.target.value)}
							/>
						</Grid>

						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>
						<Grid item xs={2}>
							<Typography style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.30)' }}>
								Thành viên
							</Typography>
						</Grid>
						<Grid item xs={5} style={{ margin: 'auto' }}>
							<Divider />{' '}
						</Grid>

						{props?.role === 'sudo-admin' && values?.purposeId === 1 && (
							<Grid item xs={12}>
								<FormControlLabel
									disabled={disabled}
									control={
										<Checkbox
											color="primary"
											checked={divideKPI}
											onChange={() => setDivideKPI(!divideKPI)}
										/>
									}
									label="Chia KPI"
								/>
							</Grid>
						)}

						<Grid item xs={12}>
							{profileList.map((e, index) => (
								<Grid key={`list-${index}`} container spacing={2}>
									<Grid
										item
										xs={3}
										style={{
											display: 'flex'
										}}
									>
										<FormControl required fullWidth error={!values?.profileIdForKpi}>
											<Button
												disabled={disabled}
												fullWidth
												color="primary"
												variant="outlined"
												style={{ height: 40 }}
												startIcon={e.isExternalTeacher ? <CheckBox /> : <CheckBoxOutlineBlank />}
												onClick={() => {
													handleChangeProfileList(index, 'isExternalTeacher', !e.isExternalTeacher);
													handleChangeProfileList(index, 'profileId', 0);
													handleChangeProfileList(index, 'name', null);
												}}
											>
												Ngoài trường
											</Button>
										</FormControl>
									</Grid>
									<Grid
										item
										xs={
											divideKPI
												? 3
												: values?.purposeId !== 1 && values?.purposeId !== 99999999
													? 3
													: 3
										}
									>
										{e.isExternalTeacher ? (
											<TextField
												disabled={disabled}
												defaultValue={e.name}
												onChange={(event) => {
													handleChangeProfileList(index, 'name', event.target.value);
													handleChangeProfileList(index, 'profileId', 0);
													if (event.target.value) setHelperText(false);
												}}
												error={helperText && e.name === null}
												fullWidth={true}
												size="small"
												label="Thành viên"
												variant="outlined"
											/>
										) : (
											<Autocomplete
												disableClearable
												disabled={disabled}
												options={props.profileList}
												getOptionLabel={(option) => `${option.code} - ${option.name}`}
												value={props.profileList?.find((profile) => profile.id === e.profileId)}
												onChange={(event, profile) => {
													if (profileList[index]?.profileId === values?.profileIdForKpi)
														handleChangeForm('profileIdForKpi', null);
													handleChangeProfileList(index, 'profileId', parseInt(profile?.id));
													handleChangeProfileList(index, 'name', profile?.name);
													handleChangeProfileList(index, 'code', profile?.code);
													if (parseInt(profile?.id) >= 0) setHelperText(false);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														error={helperText && e.profileId === null}
														size="small"
														label="Thành viên"
														variant="outlined"
													/>
												)}
											/>
										)}
										{helperText && e.profileId === null && (
											<FormHelperText style={{ color: 'red' }}>Không được để trống</FormHelperText>
										)}
									</Grid>
									<Grid item xs={values?.purposeId !== 1 && values?.purposeId !== 99999999 ? 3 : 3}>
										<FormControl variant="outlined" size="small" style={{ width: '100%' }}>
											<InputLabel
												id="purpose-label"
												style={{ padding: '0 8px', backgroundColor: 'white' }}
											>
												Vai trò
											</InputLabel>
											<Select
												native
												fullWidth
												disabled={disabled}
												labelId="purpose-label"
												value={e?.roleInPaper || 'main'}
												onChange={(event) =>
													handleChangeProfileList(index, 'roleInPaper', event.target.value)
												}
											>
												{/* <option value={null} /> */}
												<option value="main">Tác giả chính</option>
												<option value="member">Đồng tác giả</option>
											</Select>
										</FormControl>
									</Grid>
									{values?.purposeId === 1 && (
										<>
											<Grid item xs={divideKPI ? 2 : 3}>
												<FormControl
													required
													fullWidth
													disabled={disabled}
													error={!values?.profileIdForKpi}
												>
													<Button
														fullWidth
														color="primary"
														variant="outlined"
														style={{ height: 40 }}
														disabled={
															disabled || values?.locked === 'kpi' || values?.locked === 'reward'
														}
														startIcon={isCheckedForKPI(e) ? <CheckBox /> : <CheckBoxOutlineBlank />}
														onClick={() => {
															setDetail(null);
															if (!divideKPI) {
																props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
																handleChangeForm(
																	'profileIdForKpi',
																	values?.profileIdForKpi !== e.profileId
																		? parseInt(e.profileId)
																		: null
																);
															} else {
																setFocusProfile(e.profileId);
																props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
																if (KPIList.find((kpi) => e.profileId === kpi?.profileId)) {
																	setKPIList(
																		KPIList.filter((kpi) => e.profileId !== kpi?.profileId)
																	);
																} else {
																	setKPIList([
																		...KPIList,
																		{
																			profileId: e.profileId,
																			coef: 0
																		}
																	]);
																}
															}
														}}
													>
														Tính KPI
													</Button>
													{!divideKPI && !values?.profileIdForKpi && !disabled && (
														<FormHelperText>Phải chọn 1 người tính KPI</FormHelperText>
													)}
												</FormControl>
											</Grid>
											{divideKPI && (
												<Grid item xs={2}>
													<TextField
														fullWidth
														size="small"
														label="Hệ số"
														variant="outlined"
														value={KPIList.find((kpi) => e.profileId === kpi?.profileId)?.coef || 0}
														disabled={
															disabled || !KPIList.find((kpi) => e.profileId === kpi?.profileId)
														}
														onChange={(event) =>
															setKPIList([
																...KPIList.filter((kpi) => e?.profileId !== kpi?.profileId),
																{ profileId: e?.profileId, coef: event.target.value }
															])
														}
													/>
												</Grid>
											)}
										</>
									)}
									{values?.purposeId === 99999999 && (
										<Grid item xs={3}>
											<FormControl
												fullWidth
												disabled={disabled}
												error={!values?.profileIdForReward}
											>
												<Button
													fullWidth
													color="primary"
													variant="outlined"
													style={{ height: 40 }}
													disabled={
														disabled || values?.locked === 'kpi' || values?.locked === 'reward'
													}
													startIcon={
														typeof values?.profileIdForReward === 'number' &&
														values?.profileIdForReward === e.profileId ? (
															<CheckBox />
														) : (
															<CheckBoxOutlineBlank />
														)
													}
													onClick={() => {
														setDetail(null);
														props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
														setFocusProfile(e.profileId);
														handleChangeForm(
															'profileIdForReward',
															values?.profileIdForReward !== e.profileId
																? parseInt(e.profileId)
																: null
														);
													}}
												>
													Tính thưởng
												</Button>
												{!values?.profileIdForReward && !disabled && (
													<FormHelperText>Phải chọn 1 người tính thưởng</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									{divideKPI && (
										<Grid item xs={1}>
											<Button
												fullWidth
												disabled={disabled}
												variant="outlined"
												style={{ height: 40 }}
												onClick={() => {
													props.getAllEmploymentContractByProfileId(parseInt(e.profileId));
													setFocusProfile(e.profileId);
												}}
											>
												<Visibility />
											</Button>
										</Grid>
									)}
									<Grid item xs={divideKPI ? 1 : 2}>
										<Button
											className={!disabled && 'error-button'}
											fullWidth
											disabled={disabled}
											color="secondary"
											variant="contained"
											style={{ height: 40 }}
											onClick={() => handleRemove(index)}
										>
											Xoá
										</Button>
									</Grid>
								</Grid>
							))}
						</Grid>

						<Grid item xs={12}>
							<Button fullWidth disabled={disabled} variant="outlined" onClick={handleInsert}>
								Thêm thành viên
							</Button>
						</Grid>
					</Grid>
				</div>
				<div
					style={{
						flexDirection: 'column',
						display:
							values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0 ? 'block' : 'none'
					}}
				>
					<EmploymentContractTable
						values={values}
						contract={detail}
						KPIList={KPIList}
						divideKPI={divideKPI}
						focusProfile={focusProfile}
						contractList={contractList}
						kpiIdForKpi={values?.kpiIdForKpi}
						disabled={disabled || values?.locked}
						contractIdForKpi={values?.contractIdForKpi}
						setDetail={setDetail}
						setValues={setValues}
						setKPIList={setKPIList}
						handleChangeForm={handleChangeForm}
					/>
					{/* <EmploymentContractTable
            values={values}
            contract={detail}
            KPIList={KPIList}
            divideKPI={divideKPI}
            contractList={contractList}
            kpiIdForKpi={values?.kpiIdForKpi}
            disabled={disabled || values?.locked}
            contractIdForKpi={values?.contractIdForKpi}
            setDetail={setDetail}
            setValues={setValues}
            setKPIList={setKPIList}
            handleChangeForm={handleChangeForm}
          /> */}
					<EmploymentContractDetailTable disabled={disabled || values?.locked} kpi={detail} />
				</div>
				<div
					style={{
						flexDirection: 'column',
						display: needCalHour ? 'block' : 'none'
					}}
				>
					<ScientificResearchTimeTable
						profiles={profileList}
						mainProfileIds={mainProfileIds}
						totalHour={selectedTimeResearchDetail?.value ?? 0}
					/>
				</div>
			</DialogContent>
			<DialogActions style={{ padding: '0 24px 8px 24px' }}>
				<Grid container justifyContent={'space-between'}>
					<div>
						<Button
							disabled={disabled}
							variant="outlined"
							color="primary"
							onClick={handleOpenUploadModal}
							style={{ marginRight: 4 }}
						>
							Upload file minh chứng
						</Button>
						<Button variant="contained" color="primary" onClick={handleOpenPreviewModal}>
							Preview file minh chứng
						</Button>
					</div>
					<div>
						{props.adminTab && (
							<Button variant="outlined" onClick={handleSendFeedback}>
								Gửi feedback
							</Button>
						)}
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleClose}
							style={{ margin: '0 4px' }}
						>
							Huỷ
						</Button>
						{!window.location.href.includes('management') ||
						(window.location.href.includes('management') && props.role === 'sudo-admin') ? (
							<Tooltip
								disableHoverListener={props.role === 'sudo-admin'}
								title="Liên hệ phòng KHCN để đề xuất chỉnh sửa CTKH"
							>
								<span>
									<Button
										variant="contained"
										color="primary"
										disabled={
											(!disabled &&
												((values?.purposeId === 1 &&
													!(values?.profileIdForKpi && values?.contractIdForKpi)) ||
													(values?.purposeId === 99999999 && !values?.profileIdForReward) ||
													!(values?.numberMainAuthor > 0) ||
													!(values?.submissionMonth > 0) ||
													!(values?.acceptedMonth > 0) ||
													!(values?.numOfMember > 0) ||
													(values?.purposeId === 5 && !values?.timeResearchDetailCode))) ||
											values?.locked ||
											(values?.accepted && props.role !== 'sudo-admin')
										}
										onClick={handleSubmit}
									>
										{disabled ? 'Chỉnh sửa' : 'Xác nhận'}
									</Button>
								</span>
							</Tooltip>
						) : (
							<></>
						)}
					</div>
				</Grid>
			</DialogActions>
			<FileUploadModal
				open={uploadModalOpen}
				handleClose={handleCloseUploadModal}
				handleUpload={handleUpload}
			/>
			<FilePreviewModal
				open={previewModalOpen}
				handleClose={handleClosePreviewModal}
				url={values?.fileUrl}
			/>
		</Dialog>
	);
}

const mapStateToProps = (state) => ({
	role: select(state, 'userReducer', 'role'),
	history: select(state, 'homeReducer', 'history'),
	fileUrl: select(state, 'fileReducer', 'fileUrl'),
	profile: select(state, 'profileReducer', 'detail'),
	profileList: select(state, 'profileReducer', 'list'),
	isUploading: select(state, 'fileReducer', 'isFetching'),
	detailYear: select(state, 'timeResearchInformationReducer', 'detail'),
	employmentContractDetail: select(state, 'employmentContractReducer', 'detail'),
	employmentContractListById: select(state, 'employmentContractReducer', 'listById'),
	timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
	isFetchingTimeResearchInformation: select(state, 'timeResearchInformationReducer', 'isFetching')
});

const mapDispatchToProps = (dispatch) => ({
	uploadFile: (id, type, files) => dispatch(uploadFile(id, type, files)),
	statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
	getEmploymentContractById: (id) => dispatch(getEmploymentContractById(id)),
	updateScientificResearch: (id, payload) => dispatch(updateScientificResearch(id, payload)),
	getAllEmploymentContractByProfileId: (id) => dispatch(getAllEmploymentContractByProfileId(id)),
	updateScientificResearchOther: (id, payload) =>
		dispatch(updateScientificResearchOther(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(ScientificResearchUpdateModal));
