import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { API_URLS } from '../../../constants/api';

const columnCode = 'ABCDEFGHIJKL';

const getCardNumbers = async (profileIds, data) => {
	try {
		const response = await axios.post(API_URLS.SCIENTIFIC_RESEARCH.getCardNumbers().endPoint, {
			profileIds
		});
		if (response?.data?.data) {
			const result = response?.data?.data;
			for (let i = 0; i < data.length; i++) {
				let cardNumbers = [];
				let mainName = [];
				let memberName = [];
				for (let j = 0; j < result.length; j++) {
					if (result[j].researchId === data[i].id) {
						data[i].scientificResearchProfile?.map((e) => {
							if (e.profileId === 0) {
								if (e.roleInPaper === 'main') {
									cardNumbers.push('');
									mainName.push(e.name);
									return;
								}
								memberName.push(e.name);
								return;
							}
							result[j].ids?.map((el) => {
								if (el.id === e.profileId) {
									if (e.roleInPaper === 'main') {
										cardNumbers.push(el.cardNumber);
										mainName.push(el.name);
										return;
									}
									memberName.push(el.name);
								}
							});
						});
					}
				}
				data[i].cardNumbers = cardNumbers.join('\n');
				data[i].mainAuthors = mainName.join('\n');
				data[i].authors = memberName.join('\n');
			}
			return data;
		}
		throw new Error();
	} catch (err) {
		throw new Error('Có lỗi xảy ra!');
	}
};

export const exportExcel = async (_data, departmentName, date) => {
	const listIds = [];
	let data = _data.map((e, index) => {
		const profileIds = e.scientificResearchProfile?.map((e) => e.profileId);
		profileIds?.length > 0 &&
			listIds.push({
				researchId: e.id,
				ids: profileIds
			});

		return {
			...e,
			index: index + 1,
			publicationTitle: e.publication?.title,
			publicationSjrBestQuartile: e.publication?.sjrBestQuartile,
			acceptedYear: new Date(e.acceptedDay).getFullYear(),
			domesticTitle:
				e.filter === 'journalDomestic'
					? e.publication?.title
						? e.publication?.title
						: e.otherTitle
					: null
		};
	});
	data = await getCardNumbers(listIds, data);
	const fileName = `Bài báo khoa học`;
	const Workbook = new ExcelJS.Workbook();
	const worksheet = Workbook.addWorksheet('Bài báo khoa học');

	const columns = [
		{
			title: 'STT',
			key: 'index',
			width: 8
		},
		{
			title: 'Tên bài báo khoa học',
			key: 'title',
			width: 40
		},
		{
			title: 'Danh sách tác giả chính',
			key: 'mainAuthors',
			width: 25
		},
		{
			title: 'Số CCCD của tác giả chính',
			key: 'cardNumbers',
			width: 25
		},
		{
			title: 'Danh sách đồng tác giả',
			key: 'authors',
			width: 25
		},
		{
			title: 'Tên tạp chí',
			key: 'publicationTitle',
			width: 20
		},
		{
			title: 'Tạp chí quốc tế',
			key: 'publicationSjrBestQuartile',
			width: 20
		},
		{
			title: 'Tạp chí trong nước',
			key: 'domesticTitle',
			width: 20
		},
		{
			title: 'Ghi chú đường dẫn bài báo',
			key: 'doi',
			width: 27
		},
		{
			title: 'Tập số',
			key: 'volume',
			width: 10
		},
		{
			title: 'Trang',
			key: 'page',
			width: 10
		},
		{
			title: 'Năm công bố',
			key: 'acceptedYear',
			width: 13
		}
	];

	worksheet.columns = columns;
	worksheet.mergeCells(`A1:${columnCode[columns.length - 1]}1`);
	worksheet.getCell('A1').value = 'BÀI BÁO KHOA HỌC ĐÃ CÔNG BỐ';

	worksheet.addRow(columns.map((e) => e.title));
	data.forEach((row) => worksheet.addRow(columns.map((col) => row[col.key])));

	for (let i = 0; i < worksheet.columnCount; i++) {
		for (let j = 1; j <= worksheet.rowCount; j++) {
			worksheet.getCell(`${columnCode[i]}${j}`).style = {
				...worksheet.getCell(`${columnCode[i]}${j}`).style,
				alignment: { wrapText: true, vertical: 'middle' },
				border: {
					top: { style: 'thin', color: { argb: 'FF000000' } },
					left: { style: 'thin', color: { argb: 'FF000000' } },
					bottom: { style: 'thin', color: { argb: 'FF000000' } },
					right: { style: 'thin', color: { argb: 'FF000000' } }
				}
			};
		}
	}

	worksheet.getCell('A1').alignment = { horizontal: 'center' };
	worksheet.getRow(2).alignment = { horizontal: 'center' };
	worksheet.getRow(1).font = { bold: true };
	worksheet.getRow(2).font = { bold: true };

	const buf = await Workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `${fileName}.xlsx`);
};
