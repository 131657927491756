import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { API_URLS } from '../../../constants/api';

const columnCode = 'ABCDEFGHI';
const MONOGRAPH_TYPE = [
	{
		text: 'Chương sách',
		value: 'chapter'
	},
	{
		text: 'Chuyên khảo',
		value: 'monograph'
	},
	{
		text: 'Tham  khảo',
		value: 'reference'
	},
	{
		text: 'Giáo trình',
		value: 'curriculum'
	}
];

const getCardNumbers = async (profileIds, data) => {
	try {
		const response = await axios.post(API_URLS.SCIENTIFIC_RESEARCH.getCardNumbers().endPoint, {
			profileIds
		});
		if (response?.data?.data) {
			const result = response?.data?.data;
			for (let i = 0; i < data.length; i++) {
				let cardNumbers = [];
				let mainName = [];
				let memberName = [];
				for (let j = 0; j < result.length; j++) {
					if (result[j].researchId === data[i].id) {
						data[i].monographProfile?.map((e) => {
							if (e.profileId === 0) {
								if (e.roleInMonograph === 'main') {
									cardNumbers.push('');
									mainName.push(e.name);
									return;
								}
								memberName.push(e.name);
								return;
							}
							result[j].ids?.map((el) => {
								if (el.id === e.profileId) {
									if (e.roleInMonograph === 'main') {
										cardNumbers.push(el.cardNumber);
										mainName.push(el.name);
										return;
									}
									memberName.push(el.name);
								}
							});
						});
					}
				}
				data[i].cardNumbers = cardNumbers.join('\n');
				data[i].mainAuthors = mainName.join('\n');
				data[i].authors = memberName.join('\n');
			}
			return data;
		}
		throw new Error();
	} catch (err) {
		throw new Error('Có lỗi xảy ra!');
	}
};

export const exportExcel = async (_data, departmentName, date) => {
	const listIds = [];
	let data = _data.map((e, index) => {
		const profileIds = e.monographProfile?.map((e) => e.profileId);
		profileIds?.length > 0 &&
			listIds.push({
				researchId: e.id,
				ids: profileIds
			});

		return {
			...e,
			index: index + 1,
			category: MONOGRAPH_TYPE.filter((el) => el.value === e.type)[0]?.text,
			status: e.accepted ? 'Đã được duyệt' : 'Chưa được duyệt'
		};
	});
	data = await getCardNumbers(listIds, data);
	const fileName = `Danh mục các sách, giáo trình, tài liệu tham khảo`;
	const Workbook = new ExcelJS.Workbook();
	const worksheet = Workbook.addWorksheet('Danh mục các sách, giáo trình, tài liệu tham khảo');

	const columns = [
		{
			title: 'STT',
			key: 'index',
			width: 8
		},
		{
			title: 'Tên sách chuyên khảo',
			key: 'title',
			width: 40
		},
		{
			title: 'Danh sách chủ biên',
			key: 'mainAuthors',
			width: 25
		},
		{
			title: 'Số CCCD của chủ biên',
			key: 'cardNumbers',
			width: 25
		},
		{
			title: 'Danh sách đồng tác giả',
			key: 'authors',
			width: 25
		},
		{
			title: 'Phân loại',
			key: 'category',
			width: 20
		},
		{
			title: 'Nhà xuất bản',
			key: 'publisher',
			width: 20
		},
		{
			title: 'Tình trạng',
			key: 'status',
			width: 20
		},
		{
			title: 'Năm xuất bản',
			key: 'year',
			width: 13
		}
	];

	worksheet.columns = columns;
	worksheet.mergeCells(`A1:${columnCode[columns.length - 1]}1`);
	worksheet.getCell('A1').value = 'Danh mục các sách, giáo trình, tài liệu tham khảo';

	worksheet.addRow(columns.map((e) => e.title));
	data.forEach((row) => worksheet.addRow(columns.map((col) => row[col.key])));

	for (let i = 0; i < worksheet.columnCount; i++) {
		for (let j = 1; j <= worksheet.rowCount; j++) {
			worksheet.getCell(`${columnCode[i]}${j}`).style = {
				...worksheet.getCell(`${columnCode[i]}${j}`).style,
				alignment: { wrapText: true, vertical: 'middle' },
				border: {
					top: { style: 'thin', color: { argb: 'FF000000' } },
					left: { style: 'thin', color: { argb: 'FF000000' } },
					bottom: { style: 'thin', color: { argb: 'FF000000' } },
					right: { style: 'thin', color: { argb: 'FF000000' } }
				}
			};
		}
	}

	worksheet.getCell('A1').alignment = { horizontal: 'center' };
	worksheet.getRow(2).alignment = { horizontal: 'center' };
	worksheet.getRow(1).font = { bold: true };
	worksheet.getRow(2).font = { bold: true };

	const buf = await Workbook.xlsx.writeBuffer();
	saveAs(new Blob([buf]), `${fileName}.xlsx`);
};
