import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllStudentResearch = () => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.getAllStudentResearch();
	dispatch({
		type: TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const insertStudentResearch = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.insertStudentResearch(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thêm đề tài thành công!'
			})
		);
		return true;
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
		dispatch(
			notification({
				type: 'error',
				message: `Thêm đề tài thất bại! ${error.response}`
			})
		);
	}
};

export const updateStudentResearch = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.updateStudentResearch(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Cập nhật đề tài thành công!'
			})
		);
		return true;
	}
	dispatch({
		type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_FAILURE,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
	});
	dispatch(
		notification({
			type: 'error',
			message: `Cập nhật đề tài thất bại! ${JSON.parse(error.response).message}`
		})
	);
};

export const updateStudentResearchStatus = (id, status) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.updateStudentResearchStatus(id, status);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_SUCCESS,
			id,
			status,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Duyệt thành viên đề tài thành công!'
			})
		);
		return true;
	}
	dispatch({
		type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_FAILURE,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
	});
	dispatch(
		notification({
			type: 'error',
			message: `Duyệt thành viên đề tài thất bại! ${JSON.parse(error.response).message}`
		})
	);
};

export const filterStudentResearch = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.filterStudentResearch(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
		return false;
	}
};

export const filterStudentResearchV2 = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.filterStudentResearchV2(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const deleteStudentResearch = (id) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.deleteStudentResearch(id);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_SUCCESS,
			id,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const insertStudentGuidance = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.insertStudentGuidance(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_GUIDANCE_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_GUIDANCE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.INSERT_STUDENT_GUIDANCE_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const updateStudentGuidance = (payload) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.updateStudentGuidance(payload);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_GUIDANCE_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api, payload });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_GUIDANCE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_GUIDANCE_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const deleteStudentGuidance = (id) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.deleteStudentGuidance(id);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_GUIDANCE_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_GUIDANCE_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		return true;
	} else {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.DELETE_STUDENT_GUIDANCE_FAILURE,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
		});
	}
};

export const revertStatus = (id) => async (dispatch) => {
	const api = API_URLS.STUDENT_RESEARCH.revertStatus(id);
	dispatch({
		type: TYPE.STUDENT_RESEARCH.REVERT_STATUS_LOADING,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_CALLING] }
	});
	const { response, error } = await apiCall({ ...api });
	if (!error && response.status === 200 && response.data.success) {
		dispatch({
			type: TYPE.STUDENT_RESEARCH.REVERT_STATUS_SUCCESS,
			payload: response.data.data,
			meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_SUCCESS] }
		});
		dispatch(
			notification({
				type: 'success',
				message: 'Thay đổi trạng thái thành công!'
			})
		);
		return true;
	}
	dispatch({
		type: TYPE.STUDENT_RESEARCH.REVERT_STATUS_FAILURE,
		meta: { prefix: [PREFIX.STUDENT_RESEARCH, PREFIX.API_FAILURE] }
	});
	dispatch(
		notification({
			type: 'error',
			message: `Thay đổi trạng thái thất bại!`
		})
	);
};
