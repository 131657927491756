import {
	Box,
	Select,
	Divider,
	MenuItem,
	Checkbox,
	TextField,
	Typography,
	OutlinedInput,
	FormControlLabel,
	IconButton,
	Tooltip,
	Backdrop,
	Fab
} from '@material-ui/core';
import axios from 'axios';

import { Autocomplete } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { Fragment, useState } from 'react';
import {
	InsertDriveFile,
	GetApp,
	ExpandLess,
	ExpandMore,
	Close,
	Delete,
	Edit
} from '@material-ui/icons';

import { KPTimePicker } from '../index';

import styles from './index.module.css';
import { valueFormat } from '../../../utils/valueFormat';

function KPTextfield(props) {
	const { id, type, label, value, options, disabled, required, className, handleChangeForm } =
		props;

	const [delay, setDelay] = useState(null);
	const [isFocus, setFocus] = React.useState(false);
	const [openPreview, setOpenPreview] = React.useState(null);

	// Error màu đỏ, còn lại màu xanh đen
	// const color = error ? "#c90000" : "#031043";
	const color = '#031043';

	const getRange = (min, max, num) => Math.min(max, Math.max(min, parseFloat(num)));

	const downloadAs = (url, name) => {
		axios
			.get(url, {
				headers: {
					'Content-Type': 'application/octet-stream'
				},
				responseType: 'blob'
			})
			.then((response) => {
				const a = document.createElement('a');
				const url = window.URL.createObjectURL(response.data);
				a.href = url;
				a.download = name;
				a.click();
			})
			.catch((err) => {
				// console.log("error", err);
			});
	};

	return (
		<>
			{label && (
				<>
					{typeof label === 'string' ? (
						<Typography className="kpt-textfield-label" style={{ color }}>
							{label}
							{required ? <span style={{ color: '#c90000' }}>*</span> : ''}
							{label ? ':' : ''}
						</Typography>
					) : (
						label(color)
					)}
				</>
			)}
			{(() => {
				switch (type) {
					case 'autoComplete': {
						return (
							<Autocomplete
								// className={className}
								disableClearable
								disabled={disabled}
								placeholder={props?.placeholder}
								options={options || []}
								getOptionLabel={props.getOptionLabel}
								onChange={(_, val) => handleChangeForm(id, val, type)}
								value={
									value ||
									(() => {
										const defaultValue = { ...options?.[0] };
										for (var property in defaultValue) defaultValue[property] = '';
										return defaultValue;
									})()
								}
								renderInput={(params) => (
									<TextField
										{...params}
										// className={className}
										required={required}
										placeholder={props?.placeholder}
										size="small"
										variant="outlined"
										InputProps={{
											...params.InputProps,
											className: `${params.InputProps.className} ${className}`
										}}
										inputProps={{
											...params.inputProps,
											style: { ...params.inputProps.style, padding: '2px' }
										}}
										style={{
											...params.style,
											padding: 0,
											backgroundColor: 'white',
											borderRadius: 4
										}}
									/>
								)}
							/>
						);
					}
					case 'dragzone':
						return (
							<>
								{typeof value === 'string' && value !== '' ? (
									<>
										{(() => {
											const { fullname, name, type, size } = valueFormat.pathToInfo(value);

											return (
												<Box
													borderRadius={4}
													height={90}
													width="calc(100% - 6px)"
													border="dashed 3px #0000001f"
													padding="12px 0"
													bgcolor="#FAFAFA"
												>
													<Box display="flex">
														<Box position="relative">
															<InsertDriveFile style={{ color: '#00000080', fontSize: 80 }} />
															<Box
																position="absolute"
																textAlign="center"
																color="#fff"
																top={36}
																left={14}
																width={52}
															>
																<b style={{ color: '#fff', textTransform: 'uppercase' }}>{type}</b>
															</Box>
														</Box>
														<Box flexGrow={1}>
															<Typography>
																<b>
																	{name.length > 16
																		? `${name?.slice(0, 8)}...${name?.slice(name.length - 8)}`
																		: name}
																	{`.${type}`}
																</b>
															</Typography>
															<Typography>{valueFormat.formatBytes(size)}</Typography>
														</Box>
														<Box marginRight={1} marginTop="28px">
															{!disabled && (
																<Fragment>
																	<Tooltip title="Cập nhật">
																		<IconButton
																			onClick={() =>
																				document.getElementById(`file-upload-${id}`).click()
																			}
																		>
																			<Edit />
																		</IconButton>
																	</Tooltip>
																	<input
																		id={`file-upload-${id}`}
																		type="file"
																		style={{ display: 'none' }}
																		onChange={(e) => handleChangeForm(id, e.target.files, 'file')}
																	/>
																</Fragment>
															)}
															<Tooltip title="Tải xuống">
																<IconButton
																	// onClick={() => setOpenPreview(`${process.env.REACT_APP_DOMAIN}${value}`)}
																	onClick={() =>
																		downloadAs(`${process.env.REACT_APP_DOMAIN}${value}`, fullname)
																	}
																	// style={{ width: 48, height: 48, paddingTop: 14 }}
																>
																	{/* <a href={`${process.env.REACT_APP_DOMAIN}${value}`} download='renamed'> */}
																	{/* ok */}
																	<GetApp />
																	{/* </a> */}
																</IconButton>
															</Tooltip>
														</Box>
													</Box>
												</Box>
											);
										})()}
									</>
								) : (
									<DropzoneArea
										filesLimit={props?.filesLimit || 1}
										maxFileSize={10485760}
										useChipsForPreview
										style={{ marginTop: 26, height: 40 }}
										dropzoneProps={{ disabled: props?.disabled }}
										dropzoneText="Upload file tại đây"
										onChange={(files) => handleChangeForm(id, files, 'file')}
										previewChipProps={{ style: { maxWidth: '75%' } }}
										previewGridProps={{ item: { style: { width: '100%' } } }}
										getFileAddedMessage={(fileName) => `Đã thêm file ${fileName}!`}
										getFileRemovedMessage={(fileName) => `Đã bỏ file ${fileName}!`}
										getFileLimitExceedMessage={(filesLimit) =>
											`Kích thước file tối đa: ${filesLimit}!`
										}
									/>
								)}
							</>
						);
					case 'date':
						return (
							<KPTimePicker
								id={id}
								value={value}
								isFocus={isFocus}
								disabled={disabled}
								required={required}
								disabledDate={props.disabledDate}
								handleChangeForm={(val) => handleChangeForm(id, val, type)}
							/>
						);
					case 'checkbox':
						return (
							<Box
								paddingLeft="12px"
								border="solid 1px #c4c4c4"
								borderRadius={4}
								bgcolor={disabled ? '#fafafa' : 'white'}
								height={33}
								{...props.style}
							>
								<FormControlLabel
									control={
										<Checkbox
											disabled={disabled}
											checked={value}
											size="small"
											color="primary"
											onChange={(e) => handleChangeForm(id, e.target.checked, type)}
										/>
									}
									style={{ marginTop: -2 }}
									label={
										<Typography
											className={styles.noselect}
											style={{
												color: disabled ? '#9B9B9B' : '#031043',
												fontSize: 14,
												marginTop: -2
											}}
										>
											{props.name}
										</Typography>
									}
								/>
							</Box>
						);
					case 'select':
						return (
							<Select
								className={className}
								fullWidth
								size="small"
								variant="outlined"
								value={value || ''}
								required={required}
								disabled={props?.disabled}
								style={{ backgroundColor: 'white' }}
								SelectDisplayProps={{ style: { padding: 8, borderRadius: 4 } }}
								MenuProps={{
									getContentAnchorEl: null,
									MenuListProps: { style: { padding: 0 } },
									anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
									transformOrigin: { vertical: 'top', horizontal: 'center' }
								}}
								onFocus={() => setFocus(true)}
								onBlur={() => setFocus(false)}
								onChange={(e) => handleChangeForm(id, e.target.value, type)}
							>
								{options.map((opt) => (
									<MenuItem key={`${id}-${opt.key}`} value={opt.value}>
										{opt.title}
									</MenuItem>
								))}
							</Select>
						);
					case 'number': {
						const { min, max, step } = props;
						return (
							<TextField
								className={className}
								fullWidth
								size="small"
								value={value}
								required={required}
								variant="outlined"
								type="number"
								min={min}
								max={max}
								step={step}
								onBlur={() => setFocus(false)}
								onFocus={() => setFocus(true)}
								disabled={props?.disabled}
								placeholder={props?.placeholder}
								inputProps={{ style: { padding: 8 } }}
								style={{ padding: 0, backgroundColor: 'white', borderRadius: 4 }}
								onChange={(e) =>
									handleChangeForm(id, getRange(min, max, parseFloat(e.target.value)), type)
								}
								InputProps={{
									style: { paddingRight: 0 },
									endAdornment: (
										<Box display="flex">
											<Divider
												orientation="vertical"
												style={{
													height: 35,
													backgroundColor: isFocus ? '#243474' : '#bdbdbd',
													width: isFocus ? 2 : 1
												}}
											/>
											<Box
												width={17.5}
												display="flex"
												flexDirection="column"
												justifyContent="center"
												alignItems="center"
											>
												<ExpandLess
													className={
														props?.disabled
															? styles.numDisabledIcon
															: // : isFocus
																// ? styles.focus
																styles.numIcon
													}
													// eslint-disable-next-line max-len
													onClick={() => {
														if (!props?.disabled)
															handleChangeForm(id, getRange(min, max, value + step), type);
													}}
													style={{ height: `calc(50% - ${isFocus ? 1 : 0.5}px)` }}
												/>
												<Divider
													style={{
														width: 17.5,
														backgroundColor: isFocus ? '#243474' : '#bdbdbd',
														height: isFocus ? 2 : 1
													}}
												/>
												<ExpandMore
													className={props?.disabled ? styles.numDisabledIcon : styles.numIcon}
													// eslint-disable-next-line max-len
													onClick={() => {
														if (!props?.disabled)
															handleChangeForm(id, getRange(min, max, value - step), type);
													}}
													style={{ height: `calc(50% - ${isFocus ? 2 : 0.5}px)` }}
												/>
											</Box>
										</Box>
									)
								}}
							/>
						);
					}
					case 'render':
						return props.render();
					default:
						return (
							<OutlinedInput
								className={className}
								fullWidth
								size="small"
								value={!delay ? value || '' : undefined}
								variant="outlined"
								type={type}
								required={required}
								minRows={props?.height}
								maxRows={15}
								disabled={props?.disabled}
								multiline={!isNaN(props?.height)}
								placeholder={props?.placeholder}
								inputProps={{ style: { padding: 8, borderRadius: 4 } }}
								style={{ padding: 0, backgroundColor: 'white', zIndex: 2 }}
								onChange={(e) => {
									if (delay) clearTimeout(delay);
									const newDelay = setTimeout(() => {
										handleChangeForm(id, e.target.value, type);
										setDelay(null);
									}, 30);
									setDelay(newDelay);
								}}
							/>
						);
				}
			})()}

			{/* <Backdrop open={!!openPreview} style={{ zIndex: 100000 }}>
        <Box width='10%' />
        <iframe style={{ width: '80%', height: '95%' }} src={openPreview} title='preview' frameBorder={0} download="renamed.txt" />
        <Box width='10%' height='100%' position='relative'>
          <Fab size='medium' onClick={() => setOpenPreview(null)} style={{ float: 'right', marginRight: 16, marginTop: 16 }}>
            <Close />
          </Fab>
        </Box>
      </Backdrop> */}
		</>
	);
}

export default KPTextfield;
