import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { Print, SaveOutlined } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import JSZip from 'jszip';
import dayjs from 'dayjs';

import ScientificProfileForm from './ScientificProfileForm';
import { ScientificProfileProvider } from './ScientificProfileContext';
import toJS from '../../../hoc/toJS';
import { API_URLS } from '../../../constants/api';
import { apiCall } from '../../../utils/api';
import select from '../../../utils/select';
import { notification } from '../../../reducer/home/action';

// DOCX
import { _RELS__DOTRELS } from '../../../utils/docx/_rels/.rels';
import { CUSTOMXML___RELS__ITEM1DOTXMLDOTRELS } from '../../../utils/docx/customXML/_rels/item1.xml.rels';
import { CUSTOMXML__ITEM1DOTXML } from '../../../utils/docx/customXML/item1.xml';
import { CUSTOMXML__ITEMPROPS1DOTXML } from '../../../utils/docx/customXML/itemProps1.xml';
import { DOCPROPS__COREDOTXML } from '../../../utils/docx/docProps/core.xml';
import { WORD___RELS__DOCUMENTDOTXMLDOTRELS } from '../../../utils/docx/word/_rels/document.xml.rels';
import { WORD__THEME__THEME1DOTXML } from '../../../utils/docx/word/theme/theme1.xml';
import { WORD__FONTTABLEDOTXML } from '../../../utils/docx/word/fontTable.xml';
import { WORD__NUMBERINGDOTXML } from '../../../utils/docx/word/numbering.xml';
import { WORD__SETTINGSDOTXML } from '../../../utils/docx/word/settings.xml';
import { WORD__STYLESDOTXML } from '../../../utils/docx/word/styles.xml';
import { CONTENT_TYPES } from '../../../utils/docx/Content_Types.xml';
import { createDocument } from '../../../utils/document';

const scientificProfileSchema = yup.object().shape({
	basicInfo: yup.object().shape({
		name: yup.string().required('Không được để trống'),
		birth: yup.date().required('Không được để trống'),
		nationality: yup.string().required('Không được để trống'),
		degree: yup.string().required('Không được để trống'),
		unit: yup.string().required('Không được để trống'),
		position: yup.string().required('Chức vụ'),
		address: yup.string().required('Không được để trống'),
		email: yup.string().email('Email không hợp lệ').required('Không được để trống'),
		phone: yup.string().required('Không được để trống')
		// base64: yup.string().required('Không được để trống'),
	}),
	educations: yup.array().of(
		yup.object().shape({
			duration: yup.string().required('Không được để trống'),
			degree: yup.string().required('Không được để trống'),
			major: yup.string().required('Không được để trống'),
			unit: yup.string().required('Không được để trống')
		})
	),
	businesses: yup.array().of(
		yup.object().shape({
			duration: yup.string().required('Không được để trống'),
			unit: yup.string().required('Không được để trống'),
			job: yup.string().required('Không được để trống')
		})
	),
	languages: yup.array().of(
		yup.object().shape({
			language: yup.string().required('Không được để trống'),
			reading: yup.string().required('Không được để trống'),
			writing: yup.string().required('Không được để trống'),
			speaking: yup.string().required('Không được để trống')
		})
	),
	scientificResearchs: yup.object().shape({
		researchAreas: yup.array().of(yup.string().required('Không được để trống')),
		researchProjects: yup.array().of(
			yup.object().shape({
				// name: yup.string().required('Không được để trống'),
				// host: yup.string().required('Không được để trống'),
				// duration: yup.string().required('Không được để trống'),
				// role: yup.string().required('Không được để trống'),
			})
		),
		isiScopusPublishments: yup.array().of(
			yup.object().shape({
				title: yup.string().required('Không được để trống')
			})
		),
		publishments: yup.array().of(
			yup.object().shape({
				title: yup.string().required('Không được để trống')
			})
		),
		domesticPublishments: yup.array().of(
			yup.object().shape({
				title: yup.string().required('Không được để trống')
			})
		),
		patentsOrSolutionsOrMonographs: yup.object().shape({
			patents: yup.array().of(
				yup.object().shape({
					title: yup.string().required('Không được để trống')
				})
			),
			solutions: yup.array().of(
				yup.object().shape({
					title: yup.string().required('Không được để trống')
				})
			),
			monographs: yup.array().of(
				yup.object().shape({
					title: yup.string().required('Không được để trống')
				})
			)
		})
	}),
	experiences: yup.object().shape({
		doctors: yup.array().of(
			yup.object().shape({
				name: yup.string().required('Không được để trống'),
				unit: yup.string().required('Không được để trống'),
				year: yup.string().required('Không được để trống'),
				role: yup.string().required('Không được để trống')
			})
		),
		masters: yup.array().of(
			yup.object().shape({
				name: yup.string().required('Không được để trống'),
				unit: yup.string().required('Không được để trống'),
				year: yup.string().required('Không được để trống'),
				role: yup.string().required('Không được để trống')
			})
		)
	})
});

const scientificProfileInitialValues = {
	basicInfo: {
		name: '',
		birth: '',
		nationality: '',
		degree: '',
		unit: '',
		address: '',
		email: '',
		phone: '',
		position: '',
		base64: ''
	},
	educations: [
		{
			duration: '',
			degree: '',
			major: '',
			unit: ''
		}
	],
	businesses: [
		{
			duration: '',
			unit: '',
			job: ''
		}
	],
	languages: [
		{
			language: '',
			reading: '',
			writing: '',
			speaking: ''
		}
	],
	scientificResearchs: {
		researchAreas: [''],
		researchProjects: [],
		isiScopusPublishments: [],
		publishments: [],
		scientificResearchOthers: [],
		domesticPublishments: [],
		patentsOrSolutionsOrMonographs: {
			patents: [], // { title: string, id: number }
			solutions: [], // { title: string, id: number }
			monographs: [] // { title: string, id: number }
		}
	},
	experiences: {
		doctors: [
			{
				name: '',
				unit: '',
				year: '',
				role: ''
			}
		],
		masters: [
			{
				name: '',
				unit: '',
				year: '',
				role: ''
			}
		]
	}
};

//Need fix hieunt
function toDataURL(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

function ScientificProfile(props) {
	const {
		profile,
		notification,
		researchProjects,
		scientificResearchs,
		scientificResearchOthers,
		patents,
		solutions,
		monographs
	} = props;
	const [initialValues, setInitialValues] = useState(scientificProfileInitialValues);

	//Need fix hieunt
	useEffect(() => {
		if (profile?.avatar && profile?.avatar !== '') {
			toDataURL(`${process.env.REACT_APP_DOMAIN}${profile?.avatar}`, function (dataUrl) {
				setInitialValues((prevInitialValues) => {
					return {
						...prevInitialValues,
						basicInfo: {
							...prevInitialValues.basicInfo,
							base64: dataUrl
						}
					};
				});
			});
		}
	}, [profile?.avatar]);

	useEffect(() => {
		(async () => {
			if (profile !== null) {
				const apiProfileInfo = API_URLS.PROFILE.getProfileInfoByProfileId(profile.id);
				const { response: responseProfileInfo, error: errorProfileInfo } = await apiCall({
					...apiProfileInfo
				});
				if (
					!errorProfileInfo &&
					responseProfileInfo.status === 200 &&
					responseProfileInfo.data.success
				) {
					const { profile: profileInfo } = responseProfileInfo.data.data;

					//ditconmenonx
					setInitialValues((prevInitialValues) => {
						return {
							...prevInitialValues,
							basicInfo: {
								...prevInitialValues.basicInfo,
								address: profileInfo?.address,
								birth: dayjs(profileInfo?.birth).format('YYYY-MM-DD'),
								degree: profileInfo?.degree,
								phone: profileInfo?.phone,
								email: profileInfo?.mail,
								name: profileInfo?.name,
								nationality: profileInfo?.nationality ?? '',
								position: profileInfo?.cvInfo?.position ?? '',
								unit: profileInfo?.cvInfo?.department ?? '',
								code: profileInfo?.code,
								major: profileInfo?.major,
								identityNumber: profileInfo?.identityNumber,
								avatar: profileInfo?.avatar
							},
							educations: (profileInfo?.cvInfo?.trainingProcessArray ?? []).map((element) => {
								return { ...element, unit: element.university };
							}),
							businesses: (profileInfo?.cvInfo?.workProcessArray ?? []).map((element) => {
								return { ...element, role: element.job };
							}),
							languages: profileInfo?.cvInfo?.foreignLanguageArray ?? [],
							scientificResearchs: {
								...prevInitialValues.scientificResearchs,
								researchAreas: profile?.cvInfo?.mainResearchFieldArray ?? [],
								domesticPublishments:
									profileInfo?.cvInfo?.listScientificResearchDomesticId
										?.map((defaultId) => {
											var domesticElement = scientificResearchs.filter(
												(scientificResearchElement) => {
													return scientificResearchElement.scientificResearch.id === defaultId;
												}
											)[0];
											if (!domesticElement) return;
											const id = domesticElement.scientificResearch.id;
											const authors = (domesticElement.scientificResearch.listAuthor ?? []).join(
												', '
											);
											const paperTitle = domesticElement.scientificResearch.title;
											const year = domesticElement.scientificResearch.year;
											const journalTitle =
												domesticElement?.journalDomestic?.title ??
												domesticElement?.scientificResearch?.otherTitle ??
												'';
											const volume = domesticElement.scientificResearch.volume;
											const page = domesticElement.scientificResearch.page;
											const title = `${authors} (${year}). ${paperTitle}. ${journalTitle}. Volume ${volume}, pp ${page}.`;
											return { id, title, year };
										})
										?.filter((item) => item !== undefined) ?? [],
								isiScopusPublishments:
									profileInfo?.cvInfo?.listScientificResearchId
										?.map((defaultId) => {
											var isiScopusElement = scientificResearchs.filter(
												(scientificResearchElement) => {
													return scientificResearchElement.scientificResearch.id === defaultId;
												}
											)[0];
											if (isiScopusElement) {
												const id = isiScopusElement.scientificResearch.id;
												const authors = (isiScopusElement.scientificResearch.listAuthor ?? []).join(
													', '
												);
												const paperTitle = isiScopusElement.scientificResearch.title;
												const year = isiScopusElement.scientificResearch.year;
												const journalTitle =
													isiScopusElement?.journal?.title ??
													isiScopusElement?.scientificResearch?.otherTitle ??
													'';
												const volume = isiScopusElement.scientificResearch.volume;
												const page = isiScopusElement.scientificResearch.page;
												const Qranking = `${isiScopusElement?.journal?.sjrBestQuartile ?? 'Not'} ranking on Scimago`;
												const title = `${authors} (${year}). ${paperTitle}. ${journalTitle}. Volume ${volume}, pp ${page}. (${Qranking})`;
												return { id, title, year };
											}
										})
										?.filter((item) => item !== undefined) ?? [],
								patentsOrSolutionsOrMonographs: {
									patents:
										profileInfo?.cvInfo?.listPatentId
											?.map((defaultId) => {
												var patentElement = patents.filter(
													(item) => item.patent.id === defaultId
												)[0];
												if (patentElement) {
													const id = patentElement.patent.id;
													const authors =
														patentElement.patent.authorTeam ?? 'Không tìm thấy nhóm tác giả';
													const patentTitle =
														patentElement.patent.title ?? 'Không tìm thấy tiêu đề';
													const year =
														new Date(patentElement.patent.acceptedDay).getFullYear() ?? 0;
													const identificationNumber =
														patentElement.patent.identificationNumber ??
														'Không tìm thấy mã số xác nhận';
													const issueBy =
														patentElement.patent.issueBy ?? 'Không tìm thấy cơ quan cấp';
													const title = `${authors} (${year}). ${patentTitle}. ${identificationNumber}. ${issueBy}`;
													return { id, title, year };
												}
											})
											?.filter((item) => item !== undefined) ?? [],
									solutions:
										profileInfo?.cvInfo?.listSolutionId
											?.map((defaultId) => {
												return {
													...solutions.filter((item) => item.solution.id === defaultId)[0]?.solution
												};
											})
											?.filter((item) => item !== undefined) ?? [],
									monographs:
										profileInfo?.cvInfo?.listMonographId
											?.map((defaultId) => {
												var monographElement = monographs.filter(
													(item) => item.monograph.id === defaultId
												)[0];
												if (monographElement) {
													const id = monographElement.monograph.id;
													const authors = (monographElement.monograph.listMember ?? []).join(', ');
													const monographTitle =
														monographElement.monograph.title ?? 'Không tìm thấy tiêu đề';
													const year = monographElement.monograph.year ?? 0;
													const publisherTitle =
														monographElement.monograph.publisher ?? 'Không tìm thấy nhà xuất bản';
													const numOfPage = `${monographElement.monograph.pageNumber ?? 'N/A'} pages`;
													const title = `${authors}. ${monographTitle}. ${publisherTitle} (${year}). ${numOfPage}`;
													return { id, title, year };
												}
											})
											?.filter((item) => item !== undefined) ?? []
								},
								publishments:
									profileInfo?.cvInfo?.listScientificResearchOtherId
										?.map((defaultId) => {
											var element = scientificResearchOthers.filter((item) => {
												return item.scientificResearchOther.id === defaultId;
											})[0];
											const id = element.scientificResearchOther.id;
											const authors = (element.scientificResearchOther.listAuthor ?? []).join(', ');
											const paperTitle = element.scientificResearchOther.title;
											const year = element.scientificResearchOther.year;
											const title = `${authors} (${year}). ${paperTitle}.`;
											return { id, title };
										})
										?.filter((item) => item !== undefined) ?? [],
								researchProjects:
									profileInfo?.cvInfo?.listResearchProjectId
										?.map((defaultId) => {
											var tempArr = researchProjects.map(
												(researchProjectElement, researchProjectIndex) => {
													return {
														...researchProjectElement.researchProject,
														rowId: researchProjectIndex + 1
													};
												}
											);
											return { ...tempArr.filter((item) => item.id === defaultId)[0] };
										})
										?.filter((item) => item !== undefined) ?? []
							},
							experiences: {
								doctors: (profileInfo?.cvInfo?.doctoralTrainingArray ?? []).map((element) => {
									return {
										name: element.student,
										unit: element.university,
										year: element.year,
										role: element.advisor
									};
								}),
								masters: (profileInfo?.cvInfo?.masterTrainingArray ?? []).map((element) => {
									return {
										name: element.student,
										unit: element.university,
										year: element.year,
										role: element.advisor
									};
								})
							}
						};
					});
				} else {
					notification({
						type: 'error',
						message: 'Không lấy được hồ sơ'
					});
				}
			}
		})();
	}, [profile, notification]);

	return (
		<ScientificProfileProvider>
			<Formik
				enableReinitialize={true}
				initialValues={initialValues}
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={scientificProfileSchema}
				onSubmit={async (values) => {
					// 1. Update profile
					const apiUpdateProfile = API_URLS.PROFILE.updateProfile(profile.id);
					const updateProfilePayload = {
						address: values.basicInfo.address,
						birth: dayjs(values.basicInfo.birth).format('YYYY-MM-DDThh:mm:ss[Z]'),
						degree: values.basicInfo.degree,
						mail: values.basicInfo.email,
						phone: values.basicInfo.phone,
						nationality: values.basicInfo.nationality,
						name: values.basicInfo.name,
						code: values.basicInfo.code,
						major: values.basicInfo.major,
						identityNumber: values.basicInfo.identityNumber,
						avatar: profile?.avatar,
						cvInfo: {
							position: values.basicInfo.position,
							department: values.basicInfo.unit,
							trainingProcessArray: values.educations.map((element, index) => {
								return {
									stt: index,
									duration: element.duration,
									degree: element.degree,
									major: element.major,
									university: element.unit
								};
							}),
							workProcessArray: values.businesses.map((element, index) => {
								return {
									stt: index,
									duration: element.duration,
									unit: element.unit,
									job: element.job
								};
							}),
							mainResearchFieldArray: values.scientificResearchs.researchAreas,
							foreignLanguageArray: values.languages,
							doctoralTrainingArray: values.experiences.doctors.map((element, index) => {
								return {
									stt: index,
									student: element.name,
									university: element.unit,
									year: Number(element.year),
									advisor: element.role
								};
							}),
							masterTrainingArray: values.experiences.masters.map((element, index) => {
								return {
									stt: index,
									student: element.name,
									university: element.unit,
									year: Number(element.year),
									advisor: element.role
								};
							}),
							listResearchProjectId: values.scientificResearchs.researchProjects.map((e) => e.id),
							listScientificResearchId: values.scientificResearchs.isiScopusPublishments.map(
								(e) => e.id
							),
							listScientificResearchOtherId: values.scientificResearchs.publishments.map(
								(e) => e.id
							),
							listScientificResearchDomesticId: values.scientificResearchs.domesticPublishments.map(
								(e) => e.id
							),
							listPatentId: values.scientificResearchs.patentsOrSolutionsOrMonographs.patents.map(
								(e) => e.id
							),
							listSolutionId:
								values.scientificResearchs.patentsOrSolutionsOrMonographs.solutions.map(
									(e) => e.id
								),
							listMonographId:
								values.scientificResearchs.patentsOrSolutionsOrMonographs.monographs.map(
									(e) => e.id
								)
						},
						id: profile.id, // important
						userId: profile.userId // important
					};
					const { response, error } = await apiCall({
						...apiUpdateProfile,
						payload: updateProfilePayload
					});
					if (!error && response.status === 200 && response.data.success) {
						notification({
							type: 'success',
							message: 'Cập nhật hồ sơ thành công'
						});
					} else {
						notification({
							type: 'error',
							message: 'Cập nhật hồ sơ thất bại'
						});
					}
					// 2. Export docx

					// Visit http://officeopenxml.com/anatomyofOOXML.php to understand the actual structure of docx file
					// values.basicInfo.base64 is a data URL
					// DO NOT use regex (or any string manipultation method) to extract base64 data from data url !!!
					const actualBase64 = await (await fetch(values.basicInfo.base64)).blob();
					const zip = new JSZip();
					zip.file('[Content_Types].xml', CONTENT_TYPES);

					const _rels = zip.folder('_rels');
					_rels.file('.rels', _RELS__DOTRELS);

					const customXML = zip.folder('customXML');
					customXML.folder('_rels').file('item1.xml.rels', CUSTOMXML___RELS__ITEM1DOTXMLDOTRELS);
					customXML.file('item1.xml', CUSTOMXML__ITEM1DOTXML);
					customXML.file('itemProps1.xml', CUSTOMXML__ITEMPROPS1DOTXML);

					const docProps = zip.folder('docProps');
					docProps.file('core.xml', DOCPROPS__COREDOTXML);

					const word = zip.folder('word');
					const word_rels = word.folder('_rels');
					word_rels.file('document.xml.rels', WORD___RELS__DOCUMENTDOTXMLDOTRELS);
					const word_media = word.folder('media');
					word_media.file('image1.jpg', actualBase64, { base64: true });
					const word_theme = word.folder('theme');
					word_theme.file('theme1.xml', WORD__THEME__THEME1DOTXML);

					word.file('document.xml', createDocument(values));
					word.file('fontTable.xml', WORD__FONTTABLEDOTXML);
					word.file('numbering.xml', WORD__NUMBERINGDOTXML);
					word.file('settings.xml', WORD__SETTINGSDOTXML);
					word.file('styles.xml', WORD__STYLESDOTXML);

					const content = await zip.generateAsync({ type: 'blob' });
					const linkDownload = document.createElement('a');
					linkDownload.href = window.URL.createObjectURL(
						new Blob([content], {
							type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
						})
					);
					linkDownload.setAttribute('download', 'cv.docx');
					linkDownload.click();
				}}
			>
				{(formikProps) => {
					const { handleSubmit, isValidating, isValid, values } = formikProps;

					const handleSave = async () => {
						// 1. Update profile
						// // // console.log(values)
						const apiUpdateProfile = API_URLS.PROFILE.updateProfile(profile.id);
						const updateProfilePayload = {
							address: values.basicInfo.address,
							birth: dayjs(values.basicInfo.birth).format('YYYY-MM-DDThh:mm:ss[Z]'),
							degree: values.basicInfo.degree,
							mail: values.basicInfo.email,
							phone: values.basicInfo.phone,
							nationality: values.basicInfo.nationality,
							name: values.basicInfo.name,
							major: values.basicInfo.major,
							code: values.basicInfo.code,
							identityNumber: values.basicInfo.identityNumber,
							avatar: profile?.avatar,
							cvInfo: {
								position: values.basicInfo.position,
								department: values.basicInfo.unit,
								trainingProcessArray: values.educations.map((element, index) => {
									return {
										stt: index,
										duration: element.duration,
										degree: element.degree,
										major: element.major,
										university: element.unit
									};
								}),
								workProcessArray: values.businesses.map((element, index) => {
									return {
										stt: index,
										duration: element.duration,
										unit: element.unit,
										job: element.job
									};
								}),
								mainResearchFieldArray: values.scientificResearchs.researchAreas,
								foreignLanguageArray: values.languages,
								doctoralTrainingArray: values.experiences.doctors.map((element, index) => {
									return {
										stt: index,
										student: element.name,
										university: element.unit,
										year: Number(element.year),
										advisor: element.role
									};
								}),
								masterTrainingArray: values.experiences.masters.map((element, index) => {
									return {
										stt: index,
										student: element.name,
										university: element.unit,
										year: Number(element.year),
										advisor: element.role
									};
								}),
								listResearchProjectId: values.scientificResearchs.researchProjects.map((e) => e.id),
								listScientificResearchId: values.scientificResearchs.isiScopusPublishments.map(
									(e) => e.id
								),
								listScientificResearchOtherId: values.scientificResearchs.publishments.map(
									(e) => e.id
								),
								listScientificResearchDomesticId:
									values.scientificResearchs.domesticPublishments.map((e) => e.id),
								listPatentId: values.scientificResearchs.patentsOrSolutionsOrMonographs.patents.map(
									(e) => e.id
								),
								listSolutionId:
									values.scientificResearchs.patentsOrSolutionsOrMonographs.solutions.map(
										(e) => e.id
									),
								listMonographId:
									values.scientificResearchs.patentsOrSolutionsOrMonographs.monographs.map(
										(e) => e.id
									)
							},
							id: profile.id, // important
							userId: profile.userId // important
						};
						const { response, error } = await apiCall({
							...apiUpdateProfile,
							payload: updateProfilePayload
						});
						if (!error && response.status === 200 && response.data.success) {
							notification({
								type: 'success',
								message: 'Cập nhật hồ sơ thành công'
							});
						} else {
							notification({
								type: 'error',
								message: 'Cập nhật hồ sơ thất bại'
							});
						}
					};

					return (
						<Form style={{ maxHeight: 'calc(100vh - 664px)' }}>
							<ScientificProfileForm />
							<Box padding="16px 0" position="fixed" bottom={16} bgcolor="#fff">
								<Box>
									{isValidating ? (
										<CircularProgress />
									) : isValid ? null : (
										<Box paddingBottom={2}>
											<Typography color="error">Kiểm tra lại thông tin đã nhập</Typography>
										</Box>
									)}
								</Box>
								<Box>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<SaveOutlined />}
										onClick={handleSave}
										style={{ marginRight: 10 }}
									>
										Lưu thông tin
									</Button>
									<Button
										variant="contained"
										color="primary"
										startIcon={<Print />}
										onClick={handleSubmit}
									>
										Export file DOCX
									</Button>
								</Box>
							</Box>
						</Form>
					);
				}}
			</Formik>
		</ScientificProfileProvider>
	);
}

const mapStateToProps = (state) => ({
	profile: select(state, 'profileReducer', 'detail'),
	monographs: select(state, 'profileReducer', 'monographs'),
	patents: select(state, 'profileReducer', 'patents'),
	scientificResearchs: select(state, 'profileReducer', 'scientificResearchs'),
	scientificResearchOthers: select(state, 'profileReducer', 'scientificResearchOthers'),
	researchProjects: select(state, 'profileReducer', 'researchProjects'),
	solutions: select(state, 'profileReducer', 'solutions')
});
const mapDispatchToProps = (dispatch) => ({
	notification: (payload) => dispatch(notification(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ScientificProfile));
